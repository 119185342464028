import type { DecoratedUser } from "@/types";

export const useUser = () => {
  const decoratedUser = useState(
    "appDecoratedUser",
    (): DecoratedUser | null => null
  );
  const user = useSupabaseUser();

  const { auth: authConfig } = useAppConfig();

  async function register({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<{ error: Error | null }> {
    let error = null;
    try {
      await $fetch("/api/auth/register", {
        method: "POST",
        body: { email, password },
      });
      if (authConfig.confirmEmail) {
        navigateTo(`/auth/confirm-email?email=${email}`);
      } else {
        navigateTo(authConfig.redirectAfterLogin);
      }
    } catch (err) {
      // @ts-expect-error
      error = createError(err.data.message || "Error registering user");
    }

    return { error };
  }

  async function fetchMeta() {
    if (!user.value) return;
    const data = await $fetch("/api/me");
    decoratedUser.value = data;
  }

  async function init() {
    async function setDecorated() {
      if (user.value && !decoratedUser.value) {
        await fetchMeta();
      }
      if (!user.value) {
        decoratedUser.value = null;
      }
    }
    await setDecorated();
    watch(user, setDecorated);
  }

  return {
    user: decoratedUser,
    fetchMeta,
    register,
    init,
  };
};
